import axios from './instance'

export const createDriver = (data) => {
  return axios.post('/driver', data)
}

export const getDrivers = (query) => {
  return axios.get(`/drivers?${query}`)
}

export const deleteDriverById = (id) => {
  return axios.delete(`/driver/${id}`)
}

export const getDriverById = (id) => {
  return axios.get(`/driver/${id}`)
}

export const updateDriverById = (id, data) => {
  return axios.put(`/driver/${id}`, data)
}

export const resetDriverPassword = (id) => {
  return axios.patch(`/driver/reset-password/${id}`)
}

export default {
  getDrivers,
  deleteDriverById,
  createDriver,
  getDriverById,
  updateDriverById,
  resetDriverPassword
}
