<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section class="section" v-if="isLoading">
      <b-progress size="is-large" show-value>
        Fetching Information
      </b-progress>
    </section>
    <div v-else>
      <hero-bar>
        {{routeData.name}}
        <b-dropdown aria-role="list" slot="right" class="is-right">
            <template #trigger="{ active }">
                <b-button
                    label="Actions"
                    type="is-primary"
                    :icon-right="active ? 'menu-up' : 'menu-down'" />
            </template>

            <div v-if="isStillEditable">
              <b-dropdown-item aria-role="listitem" :v-if="isStillEditable" @click="addCustomers">Add customers</b-dropdown-item>
            </div>
            <div v-if="isStillEditable">
              <b-dropdown-item aria-role="listitem" :v-if="isStillEditable" @click="updateAssignedRouteInfo">Update Assigned Info</b-dropdown-item>
            </div>
            <b-dropdown-item aria-role="listitem" @click="generateReport">Generate Report</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="$emit('close')">Close</b-dropdown-item>
        </b-dropdown>
      </hero-bar>
      <section class="section is-main-section">
        <card-component title="Driver Information" icon="account">
          <div class="columns">
            <div class="column is-half">
              <b-field label="Name">
                <p class="input">{{driverData.name}}</p>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Contact">
                <p class="input">{{driverData.contact}}</p>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Date">
                <p class="input">{{formatAssignedDate(assignedData.date)}}</p>
              </b-field>
            </div>
          </div>
        </card-component>

        <card-component
          title="Assigned Customers"
          icon="account-group"
          :headerIcon="openedDetails.length ? 'arrow-collapse-up' : 'arrow-collapse-down'"
          @header-icon-click="onCollapse"
        >
          <div v-if="assignedCustomers.length">
            <b-table
              hoverable
              :data="assignedCustomers"
              detailed
              detail-key="customerId"
              :show-detail-icon="true"
              :opened-detailed="openedDetails"

              :draggable="isStillEditable"
              @dragstart="dragstart"
              @drop="drop"
              @dragover="dragover"
              @dragleave="dragleave"
            >
              <b-table-column field="name" label="Customer Name" v-slot="props">
                  {{ props.row.customer.name }}
              </b-table-column>

              <b-table-column field="villageCode" label="Village" v-slot="props">
                  {{ customerVillagesMapping[props.row.customer.villageCode] || props.row.customer.villageCode}}
              </b-table-column>

              <b-table-column field="statusCode" label="Status" v-slot="props">
                  {{ customerStatusesMapping[props.row.statusCode] || props.row.statusCode }}
              </b-table-column>

              <b-table-column field="statusCode" label="Remarks" v-slot="props">
                  {{ props.row.remarks || '-' }}
              </b-table-column>

              <b-table-column field="completeTimestamp" label="Completed Timestamp" v-slot="props">
                  {{ props.row.completedTimestamp ? formatScheduleDate(props.row.completedTimestamp) : '-' }}
              </b-table-column>

              <b-table-column field="action" label="Action" numeric :visible="isStillEditable" v-slot="props">
                <b-dropdown aria-role="list" position="is-bottom-left">
                  <template #trigger="{ active }">
                    <b-button
                      size="is-small"
                      label="Actions"
                      type="is-primary"
                      :icon-right="active ? 'menu-up' : 'menu-down'"
                    />
                  </template>

                  <b-dropdown-item aria-role="listitem" @click="updateCustomerRemarks(props.index)">Update Remarks</b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="addCustomerBins(props.row.customerId)">Add bin</b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="removeCustomer(props.index)">Remove Customer</b-dropdown-item>
                </b-dropdown>
              </b-table-column>

              <template #detail="props">
                <div :class="`${isStillEditable ? 'bin-table-has-edit' : 'bin-table'}`">
                  <b-table :data="props.row.customer.bins" hoverable>
                    <b-table-column field="label" label="Bin Label" v-slot="binProps">
                        {{ binProps.row.label }}
                    </b-table-column>

                    <b-table-column field="size" label="Size" v-slot="binProps">
                        {{ binSizesMapping[binProps.row.assignedBinInfo[0].sizeCode] || '-' }}
                    </b-table-column>

                    <b-table-column field="service" label="Service" v-slot="binProps">
                        {{ binServicesMapping[binProps.row.assignedBinInfo[0].serviceCode] || '-' }}
                    </b-table-column>

                    <b-table-column field="type" label="Type" v-slot="binProps">
                        {{ binTypesMapping[binProps.row.assignedBinInfo[0].typeCode] || '-' }}
                    </b-table-column>

                    <b-table-column field="assessment" label="Assessment" v-slot="binProps">
                        {{ binAssessmentsMapping[binProps.row.assignedBinInfo[0].assessmentCode] || '-' }}
                    </b-table-column>

                    <b-table-column field="pickup" label="Pickup Timestamp" v-slot="binProps">
                        {{ binProps.row.assignedBinInfo[0].pickupTimestamp ? formatScheduleDate(binProps.row.assignedBinInfo[0].pickupTimestamp) : '-'  }}
                    </b-table-column>

                    <b-table-column field="dropoff" label="Dropoff Timestamp" v-slot="binProps">
                        {{ binProps.row.assignedBinInfo[0].dropoffTimestamp ? formatScheduleDate(binProps.row.assignedBinInfo[0].dropoffTimestamp) : '-' }}
                    </b-table-column>

                    <b-table-column numeric :visible="isStillEditable" v-slot="binProps">
                      <b-button type="is-danger" @click="removeBin(props.index, binProps.index)" size="is-small" icon-right="close" class="bin-remove-rounded" />
                      <!-- <b-dropdown aria-role="list" position="is-bottom-left">
                        <template #trigger="{ active }">
                          <b-button
                            size="is-small"
                            label="Actions"
                            type="is-primary"
                            :icon-right="active ? 'menu-up' : 'menu-down'" />
                        </template>

                        <b-dropdown-item aria-role="listitem">Edit Bin Info</b-dropdown-item>
                        <b-dropdown-item aria-role="listitem" @click="removeBin(props.index, binProps.index)">Remove Bin</b-dropdown-item>
                      </b-dropdown> -->
                    </b-table-column>
                  </b-table>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="orderChanged">
            <br />
            <b-field grouped position="is-right">
              <div class="control">
                <b-button @click="cancelReorder"
                  >Cancel</b-button
                >
              </div>
              <div class="control">
                <b-button @click="updateSortOrder" type="is-primary"
                  >Update</b-button
                >
              </div>
            </b-field>
          </div>
        </card-component>
      </section>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { mapGetters } from 'vuex'
import { saveAs } from 'file-saver'
import { isDateCurrentOrFuture } from '@/utils/dateHelper'
import assignRoute from '@/services/assign_route'

import HeroBar from '@/components/HeroBar'
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import AddRouteCustomers from '@/modals/AddRouteCustomers'
import AssignedAddCustomerBins from '@/modals/AssignedAddCustomerBins'
import UpdateCustomerRemarks from '@/modals/UpdateCustomerRemarks'
import AssignRoute from '@/modals/AssignRoute'

export default {
  name: 'AssignedRoute',
  components: {
    HeroBar,
    TitleBar,
    CardComponent
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      openedDetails: [],
      assignedData: {},
      driverData: {},
      routeData: {},
      assignedCustomers: [],
      orderChanged: false,
      assignedCustomersBeforeSortChange: []
    }
  },
  async mounted () {
    this.isLoading = true
    await this.getAssignedRouteInfo()
    this.isLoading = false
  },
  methods: {
    async getAssignedRouteInfo () {
      try {
        const result = await assignRoute.getAssignedRouteById(this.id)
        this.assignedData = result
        this.driverData = result.driver
        this.routeData = result.route
        this.assignedCustomers = result.assignedCustomers.sort((a, b) => a.sequence - b.sequence)
        this.openedDetails = this.assignedCustomers.map(({ customerId }) => customerId)
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error,
          queue: false
        })
        this.$emit('close')
      }
    },
    removeCustomer (idx) {
      if (this.checkIfSorting()) return

      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to remove customer?',
        onConfirm: async () => {
          try {
            if (this.assignedCustomers.length <= 1) {
              this.$buefy.toast.open('Cannot remove all customers on an assigned route')
              return
            }

            await assignRoute.removeCustomerFromAssignedRoute(this.id, this.assignedCustomers[idx].customerId)
            const tempCopy = [...this.assignedCustomers]
            tempCopy.splice(idx, 1)
            this.assignedCustomers = tempCopy
            this.$buefy.toast.open('Customer removed successfully')
          } catch (error) {
            this.$buefy.snackbar.open({
              message: error.message,
              queue: false
            })
          }
        }
      })
    },
    removeBin (customerIdx, binIdx) {
      if (this.checkIfSorting()) return

      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to remove bin?',
        onConfirm: async () => {
          try {
            const { customer, customerId } = this.assignedCustomers[customerIdx]
            if (customer.bins.length <= 1) {
              this.$buefy.toast.open('Cannot remove all customer\'s bin')
              return
            }

            await assignRoute.assignedRouteRemoveCustomerBin(
              this.id,
              customerId,
              customer.bins[binIdx].assignedBinInfo[0].binId
            )
            const tempCopy = [...customer.bins]
            tempCopy.splice(binIdx, 1)
            this.assignedCustomers[customerIdx].customer.bins = tempCopy
            this.$buefy.toast.open('Bin removed successfully')
          } catch (error) {
            this.$buefy.snackbar.open({
              message: error.message,
              queue: false
            })
          }
        }
      })
    },
    addCustomers () {
      if (this.checkIfSorting()) return

      this.$buefy.modal.open({
        component: AddRouteCustomers,
        parent: this,
        props: {
          routeId: parseInt(this.id),
          isAssigned: true
        },
        events: {
          onCustomersAdd: async ({ customers, close }) => {
            try {
              const result = await assignRoute.assignCustomersToRoute(this.id, { customerIds: customers.map(({ id }) => id) })
              this.assignedData = result
              this.driverData = result.driver
              this.routeData = result.route
              this.assignedCustomers = result.assignedCustomers.sort((a, b) => a.sequence - b.sequence)
              this.openedDetails = this.assignedCustomers.map(({ customerId }) => customerId)
              this.$buefy.toast.open('Customers added successfully')
              close()
            } catch (error) {
              this.$buefy.snackbar.open({
                message: error.message,
                queue: false
              })
            }
          }
        }
      })
    },
    addCustomerBins (customerId) {
      if (this.checkIfSorting()) return

      this.$buefy.modal.open({
        component: AssignedAddCustomerBins,
        parent: this,
        props: {
          routeId: parseInt(this.id),
          customerId: parseInt(customerId)
        },
        events: {
          onBinsAdded: async ({ bins, close }) => {
            try {
              const result = await assignRoute.assignedRouteAddCustomerBins(
                this.id,
                customerId,
                { binIds: bins.map(({ id }) => id) }
              )
              this.assignedData = result
              this.driverData = result.driver
              this.routeData = result.route
              this.assignedCustomers = result.assignedCustomers.sort((a, b) => a.sequence - b.sequence)
              this.$buefy.toast.open('Bins added successfully')
              close()
            } catch (error) {
              this.$buefy.snackbar.open({
                message: error.message,
                queue: false
              })
            }
          }
        }
      })
    },
    updateCustomerRemarks (idx) {
      if (this.checkIfSorting()) return

      const { remarks, customerId } = this.assignedCustomers[idx]
      this.$buefy.modal.open({
        component: UpdateCustomerRemarks,
        parent: this,
        props: {
          remarksProp: remarks
        },
        events: {
          save: async ({ remarks: remarksToSave, close }) => {
            try {
              await assignRoute.updateCustomerRemarks(
                this.id,
                customerId,
                { remarks: remarksToSave }
              )
              this.assignedCustomers[idx].remarks = remarksToSave
              this.$buefy.toast.open('Remarks updated successfully')
              close()
            } catch (error) {
              this.$buefy.snackbar.open({
                message: error.message,
                queue: false
              })
            }
          }
        }
      })
    },
    updateAssignedRouteInfo () {
      if (this.checkIfSorting()) return

      this.$buefy.modal.open({
        component: AssignRoute,
        parent: this,
        props: {
          isAssigned: true,
          date: new Date(this.assignedData.date),
          routeId: parseInt(this.routeData.id),
          driverId: parseInt(this.id)
        },
        events: {
          onAssign: async (returnData) => {
            try {
              const result = await assignRoute.updateAssignedRoute(this.id, {
                driverId: returnData.data.driverId,
                date: returnData.data.date
              })

              this.assignedData = result
              this.driverData = result.driver
              this.routeData = result.route

              this.$buefy.snackbar.open({
                message: 'Assigned Route updated successfully',
                queue: false
              })
              returnData.close()
            } catch (error) {
              this.$buefy.snackbar.open({
                message: error.message,
                queue: false
              })
            }
          }
        }
      })
    },
    formatScheduleDate (date) {
      return date ? format(new Date(date), 'PPpp') : ''
    },
    formatAssignedDate (date) {
      return date ? format(new Date(date), 'PPPP') : ''
    },
    onCollapse () {
      if (this.openedDetails.length) {
        this.openedDetails = []
      } else {
        this.openedDetails = this.assignedCustomers.map(({ customerId }) => customerId)
      }
    },
    dragstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      // payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (payload) {
      const droppedOnRowIndex = payload.index

      if (droppedOnRowIndex === this.draggingRowIndex) return

      payload.event.target.closest('tr').classList.remove('is-selected')
      const temp = [...this.assignedCustomers]
      // remove it first from the index;
      temp.splice(this.draggingRowIndex, 1)
      temp.splice(droppedOnRowIndex, 0, this.assignedCustomers[this.draggingRowIndex])

      if (!this.orderChanged) {
        this.assignedCustomersBeforeSortChange = this.assignedCustomers
      }

      this.assignedCustomers = temp
      this.orderChanged = true

      this.$buefy.toast.open(`Moved ${this.draggingRow.customer.name} from row ${this.draggingRowIndex + 1} to ${droppedOnRowIndex + 1}`)
    },
    checkIfSorting () {
      if (this.orderChanged) {
        this.$buefy.toast.open('Please finalize/cancel customers sorting before updating')
        return true
      }
      return false
    },
    cancelReorder () {
      this.assignedCustomers = this.assignedCustomersBeforeSortChange
      this.assignedCustomersBeforeSortChange = []
      this.orderChanged = false
    },
    async updateSortOrder () {
      try {
        await assignRoute.updateSequenceOrder(this.id, {
          customerIds: this.assignedCustomers.map(c => c.customerId)
        })
        this.$buefy.snackbar.open({
          message: 'Customer sorting updated successfully',
          queue: false
        })
        this.orderChanged = false
        this.assignedCustomersBeforeSortChange = []
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error.message,
          queue: false
        })
      }
    },
    async generateReport () {
      try {
        if (this.checkIfSorting()) return

        const filedata = await assignRoute.generateReport(this.id)
        const routeName = this.routeData.name.split(' ').join('_')
        const date = format(new Date(this.assignedData.date), 'yyyyMMdd')
        const filename = `${routeName}_${date}.pdf`

        const blob = new Blob([filedata], { type: 'application/pdf' })
        await saveAs(blob, filename)

        this.$buefy.snackbar.open({
          message: `PDF generated successfully (${filename})`,
          queue: false
        })
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error.message,
          queue: false
        })
      }
    }
  },
  computed: {
    ...mapGetters('lookups', [
      'binTypesMapping',
      'binServicesMapping',
      'binSizesMapping',
      'binAssessmentsMapping',
      'customerStatusesMapping',
      'customerVillagesMapping'
    ]),
    isStillEditable () {
      return isDateCurrentOrFuture(this.assignedData.date)
    },
    titleStack () {
      return ['Assigned Route Information']
    }
  }
}
</script>
