<template>
  <div>
    <section class="search-container">
      <div class="columns">
        <div class="column is-two-thirds content">
          <h4 v-if="this.label">{{this.label}}</h4>
        </div>
        <div class="column">
          <b-field>
            <b-datepicker
                placeholder="Set date filter..."
                icon="calendar-today"
                editable
                expanded
                v-model="dateRange"
                range
            >
            </b-datepicker>
            <b-button
                @click="clearSelectedDates"
                icon-left="close-circle"
                />
        </b-field>
        </div>
      </div>
    </section>

    <b-table
        v-if="tableData.length || isTableLoading"
        :data="tableData"
        :loading="isTableLoading"

        hoverable
        paginated
        backend-pagination
        :total="totalCount"
        :per-page="limit"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"

        backend-sorting
        :default-sort-direction="defaultTableOrder"
        :default-sort="[defaultTableOrderBy, defaultTableOrder]"
        @sort="onSort">

        <b-table-column field="routeName" label="Route Name" v-slot="props">
            {{ props.row.route.name }}
        </b-table-column>

        <b-table-column field="driverName" label="Driver Name" v-slot="props">
            {{ props.row.driver.name }}
        </b-table-column>

        <b-table-column field="contact" label="Customers" v-slot="props" :visible="mode !== 'customer'">
            {{ getCustomerCountLabel(props.row.assignedCustomers.length) }}
        </b-table-column>

        <b-table-column field="date" label="Schedule" sortable v-slot="props">
            {{ formatScheduleDate(props.row.date) }}
        </b-table-column>

        <b-table-column field="timestamp_created" label="Date Time Created" sortable v-slot="props">
            {{ formatDate(props.row.timestamp_created) }}
        </b-table-column>

        <b-table-column v-slot="props" label="Action" centered>
          <b-field grouped position="is-centered">
            <div class="control">
              <b-button type="is-primary" @click="viewAssigned(props.row.id)" size="is-small">View</b-button>
            </div>
            <div class="control" v-if="isRemoveButtonShown(props.row.date)">
              <b-button type="is-danger" @click="deleteAssigned(props.index)" size="is-small">Delete</b-button>
            </div>
          </b-field>
        </b-table-column>

    </b-table>

    <div class="content has-text-grey has-text-centered empty" v-else>
      <p>
        <b-icon icon="playlist-remove" size="is-large" />
      </p>
      <p>No records found. Assign route to start adding records.</p>
    </div>
  </div>
</template>

<script>
import assignRoute from '@/services/assign_route'
import { format } from 'date-fns'
import { isDateCurrentOrFuture } from '@/utils/dateHelper'
import tableMixins from '@/mixins/table'
import AssignedRoute from '@/modals/AssignedRoute'

export default {
  name: 'AssignedRouteList',
  mixins: [tableMixins],
  props: {
    mode: {
      type: String,
      default: 'route'
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      defaultTableOrderBy: 'date',
      defaultTableOrder: 'desc'
    }
  },
  methods: {
    fetchTableData (query) {
      let func = assignRoute.getAssignedRoutesByRouteId

      switch (this.mode) {
        case 'customer':
          func = assignRoute.getAssignedRoutesByCustomerId
          break
        case 'driver':
          func = assignRoute.getAssignedRoutesByDriverId
          break
        default:
          break
      }

      return func(this.$route.params.id, query) // { count: 0, rows: [] }
    },
    formatScheduleDate (date) {
      return format(new Date(date), 'MM/dd/yyyy')
    },
    formatDate (date) {
      return format(new Date(date), 'MM/dd/yyyy h:mm aa')
    },
    getCustomerCountLabel (count) {
      return `${count} customer${count > 1 ? 's' : ''}`
    },
    viewAssigned (id) {
      this.$buefy.modal.open({
        component: AssignedRoute,
        parent: this,
        fullScreen: true,
        canCancel: ['x'],
        props: {
          id: parseInt(id)
        }
      })
    },
    deleteAssigned (idx) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete assigned route?',
        onConfirm: async () => {
          try {
            const { id, date } = this.tableData[idx]
            if (!isDateCurrentOrFuture(date)) return

            await assignRoute.deleteAssignedRoute(id)
            this.$buefy.toast.open('Assigned Route deleted successfully')
            this.loadTableData()
          } catch (error) {
            this.$buefy.snackbar.open({
              message: error.message,
              queue: false
            })
          }
        }
      })
    },
    isRemoveButtonShown (date) {
      return isDateCurrentOrFuture(date)
    },
    clearSelectedDates () {
      this.dateRange = []
    }
  }
}
</script>

<style>

</style>
