<template>
  <form @submit.prevent="save">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Update Remarks</p>
        <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <b-field label="Label">
          <b-input
            type="textarea"
            v-model="remarks"
            placeholder="Remarks"
            required />
        </b-field>
      </section>
      <footer class="modal-card-foot buttons is-right">
        <b-button @click="$emit('close')"
          >Cancel</b-button
        >
        <b-button native-type="submit" type="is-primary" :disabled="!hasInfoChanges"
          >Update</b-button
        >
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: 'UpdateCustomerRemarks',
  props: {
    remarksProp: {
      type: String,
      default: ''
    }
  },
  methods: {
    save () {
      if (this.hasInfoChanges) {
        this.$emit('save', {
          remarks: this.remarks,
          close: () => { this.$emit('close') }
        })
      }
    }
  },
  data () {
    return {
      remarks: this.remarksProp
    }
  },
  computed: {
    hasInfoChanges () {
      return this.remarksProp !== this.remarks
    }
  }
}
</script>
