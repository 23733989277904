<template>
  <div class="modal-card custom" style="width: auto">
    <form @submit.prevent="assign">
      <header class="modal-card-head">
        <p class="modal-card-title">{{label}} Route</p>
        <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
      </header>
      <section class="modal-card-body" :class="isDropdownOpen ? 'min-height-container' : null">
        <b-progress v-if="isLoading" size="is-large" show-value>
          Fetching Information
        </b-progress>
        <div v-else>
          <b-field label="Schedule">
              <b-datepicker
                required
                v-model="selectedDate"
                placeholder="Click to select..."
                icon="calendar-today"
                :min-date="getMinDate()"
                trap-focus>
              </b-datepicker>
          </b-field>
          <b-field
            label="Routes"
          >
            <multiselect
              v-model="selectedRoute"
              :options="routeOptions"
              :disabled="isRoutePickerDisabled"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              :allow-empty="true"
              placeholder="Select Route"
              label="name"
              track-by="id"
              openDirection="bottom"
              tagPosition="bottom"
              @open="onOpen"
              @close="onClose"
              >

              <template slot="selection" slot-scope="{ values, isOpen }">
                <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} customers selected</span>
              </template>

            </multiselect>
          </b-field>
          <b-field
            label="Drivers"
          >
          <multiselect
            v-model="selectedDriver"
            :options="driverOptions"
            :disabled="isDriverPickerDisabled"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            :allow-empty="true"
            placeholder="Select Driver"
            label="name"
            track-by="id"
            openDirection="bottom"
            tagPosition="bottom"
            @open="onOpen"
            @close="onClose"
            >

            <template slot="selection" slot-scope="{ values, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} customers selected</span>
            </template>

          </multiselect>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot buttons is-right">
        <b-button @click="$emit('close')"
          >Cancel</b-button
        >
        <b-button native-type="submit" type="is-primary" :disabled="!assignButtonEnabled"
          >{{label}}</b-button
        >
      </footer>
    </form>
    </div>
</template>

<script>
import sub from 'date-fns/sub'
import format from 'date-fns/format'
import route from '@/services/route'
import driver from '@/services/driver'
import Multiselect from 'vue-multiselect'

export default {
  name: 'AssignRoute',
  components: {
    Multiselect
  },
  props: {
    routeId: {
      type: Number,
      default: null
    },
    driverId: {
      type: Number,
      default: null
    },
    date: {
      type: Date,
      default: () => new Date()
    },
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  async mounted () {
    this.isLoading = true
    await Promise.all([
      this.loadRouteOptions(),
      this.loadDriverOptions()
    ])
    this.preselectPickers()
    this.isLoading = false
  },
  data () {
    return {
      isLoading: false,
      selectedDate: this.date,
      isDropdownOpen: false,
      selectedRoute: null,
      isRoutePickerDisabled: false,
      routeOptions: [],
      selectedDriver: null,
      isDriverPickerDisabled: false,
      driverOptions: []
    }
  },
  methods: {
    assign () {
      if (this.assignButtonEnabled) {
        this.$emit('onAssign', {
          data: {
            routeId: this.selectedRoute.id,
            driverId: this.selectedDriver.id,
            date: format(this.selectedDate, 'yyyy-MM-dd')
          },
          close: () => { this.$emit('close') }
        })
      }
    },
    async loadRouteOptions () {
      try {
        const result = await route.getRoutes('orderBy=id&limit=9999')
        this.routeOptions = result.rows
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error,
          queue: false
        })
        this.$emit('close')
      }
    },
    async loadDriverOptions () {
      try {
        const result = await driver.getDrivers('orderBy=id&limit=9999')
        this.driverOptions = result.rows
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error,
          queue: false
        })
        this.$emit('close')
      }
    },
    preselectPickers () {
      if (this.routeId) {
        this.selectedRoute = this.routeOptions.find(({ id }) => id === this.routeId)
        if (this.selectedRoute) this.isRoutePickerDisabled = true
      }

      if (this.driverId) {
        this.selectedDriver = this.driverOptions.find(({ id }) => id === this.driverId)
        if (this.selectedDriver && !this.isAssigned) this.isDriverPickerDisabled = true
      }
    },
    onOpen () {
      this.isDropdownOpen = true
    },
    onClose () {
      this.isDropdownOpen = false
    },
    getMinDate () {
      return sub(new Date(), { days: 1 })
    }
  },
  computed: {
    assignButtonEnabled () {
      return this.selectedDate && this.selectedRoute && this.selectedDriver
    },
    label () {
      return this.isAssigned ? 'Update' : 'Assign'
    }
  }
}
</script>
