<template>
  <div class="modal-card custom" style="width: auto">
    <form @submit.prevent="add">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Bins</p>
        <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
      </header>
      <section class="modal-card-body" :class="isDropdownOpen ? 'min-height-container' : null">
        <b-progress v-if="isLoading" size="is-large" show-value>
          Fetching Bins List
        </b-progress>
        <div v-else>
          <label class="typo__label">Available Bins</label>
          <multiselect
            v-model="bins"
            :options="options"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Select Bins"
            label="label"
            track-by="id"
            openDirection="bottom"
            tagPosition="bottom"
            @open="onOpen"
            @close="onClose"
            >

            <template slot="selection" slot-scope="{ values, isOpen }">
              <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} bins selected</span>
            </template>

          </multiselect>
        </div>
      </section>
      <footer class="modal-card-foot buttons is-right">
        <b-button @click="$emit('close')"
          >Cancel</b-button
        >
        <b-button native-type="submit" type="is-primary" :disabled="!isSelectedChanged"
          >Add Bins</b-button
        >
      </footer>
    </form>
    </div>
</template>

<script>
import assignRoute from '@/services/assign_route'
import Multiselect from 'vue-multiselect'

export default {
  name: 'AssignedAddCustomerBins',
  components: {
    Multiselect
  },
  props: {
    customerId: {
      type: Number,
      default: 0
    },
    routeId: {
      type: Number,
      default: 0
    }
  },
  async mounted () {
    this.isLoading = true
    await this.loadAddableBins()
    this.isLoading = false
  },
  data () {
    return {
      isLoading: false,
      isDropdownOpen: false,
      bins: [],
      options: []
    }
  },
  methods: {
    add () {
      if (this.isSelectedChanged) {
        this.$emit('onBinsAdded', {
          bins: this.bins,
          close: () => { this.$emit('close') }
        })
      }
    },
    async loadAddableBins () {
      try {
        this.options = await assignRoute.getCustomerAddableBins(this.routeId, this.customerId)
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error,
          queue: false
        })
        this.$emit('close')
      }
    },
    onOpen () {
      this.isDropdownOpen = true
    },
    onClose () {
      this.isDropdownOpen = false
    }
  },
  computed: {
    isSelectedChanged () {
      return !!this.bins.length
    }
  }
}
</script>

<style scoped>
  .min-height-container {
    min-height: 450px;
  }
</style>
